import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

let fieldNames = ["officialPage", "companyName", "companyUrl", "teamSize", "github", "sourceForge", "sourceUserId"];

export default ({ data, location }) => {
  const page = data.markdownRemark;
  const fields = page.frontmatter;

  var lang = "ja";

  if ("lang" in fields && fields["lang"] != null) {
    lang = fields.lang;
  }

  var hasDataBox = false;
  for (var name of fieldNames) {
    if (name in fields && fields[name] != null) {
      hasDataBox = true;
      break;
    }
  }
  
  return (
    <Layout location={location}>
      <SEO title={(lang === "en" ? "Portfolio｜" : "ポートフォリオ｜") + fields.title} />
      <h1><Link to={"/" + lang + "/"}>＜{lang === "en" ? " Back" : "戻る"}</Link></h1>

      <div className="blog-post-container">
      <div className="blog-post">
        <h1>{fields.title}</h1>
        {hasDataBox &&
        <div class="projectDataBox">
          <table>
            {(fields.companyName && fields.companyUrl) && 
              <tr><th>{lang === "en" ? "Company:" : "会社"}</th><td><a href={fields.companyUrl} target="_blank" rel="noopener noreferrer">{fields.companyName}</a></td></tr>
            }
            {fields.officialPage && 
              <tr><th>{lang === "en" ? "Official Page:" : "公式ページ"}</th><td><a href={fields.officialPage} target="_blank" rel="noopener noreferrer">{fields.officialPage}</a></td></tr>
            }
            {
              fields.teamSize &&
              <tr><th>{lang === "en" ? "Team Size:" : "チームサイズ"}</th><td>{fields.teamSize}{lang === "en" ? (fields.teamSize === "1" ? " person" : " people") : "人"}</td></tr>
            }
            {
              fields.github &&
              <tr><th>{lang === "en" ? "Source Code:" : "ソースコード"}</th><td><a href={fields.github} target="_blank" rel="noopener noreferrer"><svg height="20" class="octicon octicon-mark-github" viewBox="0 0 16 16" version="1.1" width="20" aria-hidden="true"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg> {fields.github}</a></td></tr>
            }
            {
              fields.sourceForge &&
              <tr><th>{lang === "en" ? "Source Code:" : "ソースコード"}</th><td><a href={fields.sourceForge} target="_blank" rel="noopener noreferrer"><svg width="20" height="20" version="1.1" x="0px" y="0px" viewBox="50 50 180 180"><g><path class="st1" d="M162.5,154.8c0-28.9-10.2-42-15.6-46.9c-1.1-1-2.7-0.1-2.6,1.3c1.1,16.3-19.4,20.3-19.4,45.9h0c0,0.1,0,0.1,0,0.2c0,15.6,11.8,28.3,26.3,28.3c14.5,0,26.3-12.7,26.3-28.3c0-0.1,0-0.1,0-0.2h0c0-7.2-2.7-14.1-5.5-19.3c-0.5-1-2.1-0.6-1.9,0.3C174.9,158.1,162.5,171.8,162.5,154.8z"></path><g><path class="st1" d="M131.2,215.6c-0.7,0-1.3-0.3-1.8-0.7l-67.2-67.1c-1-1-1-2.6,0-3.6l70.9-70.9c0.5-0.5,1.1-0.7,1.8-0.7h20.4c1.2,0,2,0.8,2.3,1.6c0.3,0.7,0.3,1.9-0.5,2.7l-66.7,66.7c-1.3,1.3-1.3,3.5,0,4.9l52.7,52.7c1,1,1,2.6,0,3.6L133,214.9C132.5,215.4,131.9,215.6,131.2,215.6z"></path></g><g><path class="st1" d="M144.7,227.4c-1.2,0-2-0.8-2.3-1.5c-0.3-0.7-0.3-1.9,0.5-2.7l66.7-66.7c0.7-0.6,1-1.5,1-2.4s-0.4-1.8-1-2.4l-52.7-52.7c-1-1-1-2.6,0-3.6l10.2-10.2c0.5-0.5,1.1-0.7,1.8-0.7c0.7,0,1.3,0.3,1.8,0.7l67,67.1c0.5,0.5,0.7,1.1,0.7,1.8s-0.3,1.3-0.7,1.8l-70.9,70.9c-0.5,0.5-1.1,0.7-1.8,0.7H144.7z"></path></g></g></svg> {fields.sourceForge}</a>{fields.sourceUserId && " (" + (lang === "en" ? "as " : "") + fields.sourceUserId + (lang === "ja" ? "として" : "") + ")"}</td></tr>
            }

          </table>
        </div>
      }

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
      </div>
    </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title,
        officialPage,
        companyName,
        companyUrl,
        teamSize,
        github,
        sourceForge,
        sourceUserId,
        lang
      }
    }
  }
`